<template>
  <div id="app">
    <header>
      <!-- 应用的头部或其他内容 -->
    </header>
    <main>
      <download-report /> <!-- 使用刚刚注册的 DownloadReport 组件 -->
    </main>
    <footer>
      <!-- 应用的底部或其他内容 -->
    </footer>
  </div>
</template>

<script>
import DownloadReport from './components/DownloadReport4.vue'; // 步骤1：导入子组件

export default {
  name: 'App',
  components: { // 步骤2：注册子组件
    DownloadReport // 注册名为 'download-report' 的组件
  }
}
</script>

<style scoped>

body {
  background-color: rgba(112, 146, 190, 0.8); /* 莫兰迪蓝色背景 */
}
/* 在这里添加全局样式 */
</style>