<template>
  <div class="download-report">
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="stockCodes">股票代码(若为多只股票用英文符号分隔):</label>
        <input type="text" id="stockCodes" class="form-control" v-model="stockCodes">
      </div>
      <div class="form-group">
        <label for="reportYears">报告年份(若为多个年份用英文符号分隔):</label>
        <input type="text" id="reportYears" class="form-control" v-model="reportYears">
      </div>
      <div class="form-group">
        <label>请选择下载的报告期:</label>
        <div class="period-selection">
          <div v-for="period in periods" :key="period.value">
            <input type="checkbox" :id="period.value" :value="period.value" v-model="selectedPeriods">
            <label :for="period.value">{{ period.label }}</label>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary">生成报告</button>
    </form>
    <div v-if="loading" class="loading-text">{{ loadingText }}</div>
  </div>
</template>
<script>
import axios from 'axios';
import { saveAs } from 'file-saver'; // 导入FileSaver库

export default {
  data() {
    return {
      stockCodes: '',
      reportYears: '',
      selectedPeriods: [],
      periods: [
        { value: 'Q1_Report', label: 'Q1_Report' },
        { value: 'Semi_Report', label: 'Semi_Report' },
        { value: 'Q3_Report', label: 'Q3_Report' },
        { value: 'Annual_Report', label: 'Annual_Report' }
      ],
      loading: false,
      loadingText: '',
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.loadingText = '报告正在生成，请稍候...';

      const params = {
        stockCodes: this.stockCodes,
        reportYears: this.reportYears,
        selectedPeriods: this.selectedPeriods,
      };

      axios.post('http://59.110.221.131:8000/api/download-reports', params, {
        responseType: 'blob', // 设置响应类型为blob
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (response.status === 200) {
          // 后端成功处理请求并发送文件
          const blob = new Blob([response.data], { type: 'application/zip' });
          saveAs(blob, 'reports.zip'); // 使用FileSaver库保存文件
          this.loadingText = '报告生成成功，文件已开始下载。';
          alert('报告生成成功，文件已开始下载！');
        } else {
          // 后端返回非200状态码
          console.error("后端返回非200状态码:", response.status);
          this.loadingText = '报告生成失败，请稍后重试或检查输入数据。';
        }
        this.loading = false;
      })
      .catch(error => {
        // 请求失败
        console.error("请求失败:", error);
        this.loadingText = '报告生成失败，请稍后重试或检查输入数据。';
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped>
body {
  background-color: rgba(112, 146, 190, 0.8); /* 莫兰迪蓝色背景 */
}
.download-report {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.form-group {
  margin-bottom: 1rem;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.period-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.period-selection label {
  margin-left: 0.5rem;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-secondary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.btn-secondary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.loading-text {
  margin-top: 20px;
  text-align: center;
}
</style>


